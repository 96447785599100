export default [
    {
        id: 1,
        name: "Plotly",
        designation: "\"Front-end for ML and data science models\"",
        userLink: "https://plotly.com/",
        dated: "Updated: August 20, 2020",
        description: "Plotly is a front-end tool for data science and analytics. Working seemlessly with Pandas, Plotly is the quickest way I have found to generate stand-alone HTML pages with built-in plot functionality and data. I would highly recommend this library, as it is comparable to Matplotlib, Bokeh, Desmos, and more."
    },
    {
        id: 2,
        name: "Gatsby",
        designation: "\"The modern way to build the web\"",
        userLink: "https://www.gatsbyjs.com/",
        dated: "Updated: August 20, 2020",
        description: "While I am just getting used to Gatsby, it is a great development environment to begin establishing a website. Starting from a template is easist especially when CSS and HTML are not second nature. This website was created using Gatsby."
    },
    {
        id: 4,
        name: "Anaconda",
        designation: "The standard for Python data science tool kits",
        userLink: "https://www.anaconda.com/products/individual",
        dated: "Updated: August 20, 2020",
        description: "Anaconda is the data science tool kit I would recommend to anyone, not matter if you're a beginner or advanced programmer. Anaconda is open-source and allows for an abundent number of open-source packages to be instantiated inside of your customizable environments. There are thousands of Conda packages readily available to install, however it is possible to install external packages. The best tool associated with Anaconda (in my opinion) is Jupyter Notebooks; Jupyter allows you to run blocks of code in a cell-type format similar to that of Mathematica. This type of learning provides an easy way for programs to try different funcitons with out running entire scripts."
    },
    {
        id: 3,
        name: "XFLR5",
        designation: "Low-Reynolds number airfoil, wing, and aircraft analysis.",
        userLink: "http://www.xflr5.tech/xflr5.htm",
        dated: "Updated: August 20, 2020",
        description: "XFLR5 is a program that allows for Vortex Lattice Method, Panel Method, and other numerical computational fluid dynamic means to estimate aerodynamic characteristics. What separates XFLR from many of the otehr tools out there is its ability to complete batch runs (multiple airfoils, angles of attack, Reynolds numbers...) and generate stability derivates with inputted aircraft parameters. As a dynamics and controls student, I became very accustome this program and found it critical for design of aircraft operating at low Reynolds numbers."
    },
    
];
